<template>
  <div class="header-container" @mouseleave="mouseleave">
    <div class="nav-header">
      <div class="container">
        <div class="header-logo">
          <a href="/#/">
	          <img src="./img/k-1.png" alt="">
          </a>
        </div>
        <div class="header-menu">
          <div
            class="item-menu"
            v-for="(item, index) in textITList8"
            :key="item.id + index"
            @mouseenter="menuContent8(item)"
            :class="{ active: item.num === itemId }"
            @click="$router.push('/')"
          >
            <span class="span-1">{{ item.text }}</span>
          </div>
          <div
            class="item-menu"
            @click="toUpPush"
            v-for="(item, index) in textITList"
            :key="item.id + index"
            @mouseenter="menuContent(item)"
            :class="{ active: item.num === itemId }"
          >
            <span class="span-1">{{ item.text }}</span>
            <div class="children-1">
              <div class="children-one">
                <div class="children-one-1">
                  <div v-for="(item, i) in ITList" :key="item.machineType + i">
                    <div class="one-1-1" @mouseenter="menuLeft(i, item)">
                      <span class="one-1-2" :class="{ active: i == menuId }">{{
                        item.machineName
                      }}</span>
                      <!-- <img
                        class="one-1-3"
                        src="../assets/images/xl1.png"
                        alt=""
                      /> -->
                    </div>
                  </div>
                </div>
                <div class="children1-one-two">
                  <div class="oneUP">
                    <div
                      style="display: flex"
                      class="children-one-2"
                      v-if="ITList"
                    >
                      <ui
                        v-for="(k, index) in ITList[menuId]?.productInfoPOS"
                        :key="k.id + index"
                      >
                        <!-- <router-link :to="`/detailsGoods/${k.id}/${1}`"> -->
                          <li class="two-1" @click="detailsGoods(k,menuId)">
                            <p class="two-1-2">
                              <img :src="k.pictures[0]?.img" alt="" />
                            </p>

                            <div class="two-1-3">{{ k.productName }}</div>
                          </li>
                        <!-- </router-link> -->
                      </ui>
                    </div>
                    <!-- <router-link :to="`/desktop/${moreData}`"> -->
                    <div class="old" @click.stop="routerPush">查看更多</div>
                    <!-- </router-link> -->
                  </div>
                  <div
                    class="children-one-3"
                    @click.stop="$router.push('/hard')"
                  >
                    <p style="font-weight: 600; font-size: 14px">
                      联系技术专家
                    </p>
                    <p>
                      我们专注于为企业提供高性能的工作站。我们
                      的硬件帮助您的更好的应用于内容创作、游戏
                      设计工程、科学研究
                    </p>
                    <p>
                      点击咨询 &nbsp; &nbsp;<img
                        style="margin-top: -3px"
                        src="../assets/images/xl1.png"
                        alt=""
                      />
                      <img
                        src="../assets/images/times.png"
                        alt=""
                        style="
                          width: 42px;
                          height: 35px;
                          position: relative;
                          top: -10px;
                          left: 105px;
                          right: 0;
                        "
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="item-menu"
            v-for="(item, index) in textITList1"
            :key="item.id + index"
            id="utilitySoftwareId"
            @mouseenter="menuContent1(item)"
            :class="{ active: item.num === itemId }"
          >
            <span class="span-1">{{ item.text }}</span>
            <div class="children">
              <div class="children-one-w">
                <div class="children-one-1-w">
                  <div v-for="(item, i) in SoftwareList" :key="item.id + i">
                    <div class="one-1-1-w" @mouseenter="handleOne(i)">
                      <span
                        class="one-1-2-w"
                        :class="{ active: i == oneIndex }"
                        >{{ item.domainName }}</span
                      >
                      <!-- <img
                        class="one-1-3-w"
                        src="../assets/images/xl1.png"
                        alt=""
                      /> -->
                    </div>
                  </div>
                </div>
                <div class="children1-one-two-w">
                  <div class="children-one-2-w" v-if="SoftwareList">
                    <ui
                      v-for="j in SoftwareList[oneIndex]
                        ?.softwareResponseVOList"
                      :key="j.id"
                    >
                      <router-link :to="`/profession/${j.id}`">
                        <li class="two-1-w">
                          <p class="two-1-2-w">
                            <img :src="j.softwareLogo" alt="" />
                          </p>
                          <p class="two-1-3-w">
                            {{ j.softwareName }}
                          </p>
                        </li>
                      </router-link>
                    </ui>
                  </div>
                  <div class="children-one-3-w" @click="$router.push('/hard')">
                    <p style="font-weight: 600; font-size: 14px">
                      联系技术专家
                    </p>
                    <p>
                      我们专注于为企业提供高性能的工作站。我们
                      的硬件帮助您的更好的应用于内容创作、游戏
                      设计工程、科学研究
                    </p>
                    <p>
                      点击咨询
                      <img
                        style="margin-bottom: 3px"
                        src="../assets/images/xl1.png"
                        alt=""
                      />
                      <img
                        src="../assets/images/times.png"
                        alt=""
                        style="
                          width: 42px;
                          height: 35px;
                          position: relative;
                          top: -10px;
                          left: 105px;
                          right: 0;
                        "
                      />
                    </p>
                  </div>
                  <div
                    v-for="t in SoftwareList[oneIndex]?.childTable"
                    :key="t.id"
                  >
                    <!--<div
                      class="children-one-4-w-copy"
                      @click="
                        $router.push(
                          `/solve/${oneIndex}/${SoftwareList[oneIndex].id}`
                        )
                      "

                    >
                      <p
                        class="two-title-t-1"
                      >
                        关于{{SoftwareList[oneIndex].domainName}}
                      </p>
                      <p
                        class="two-title-t-2"
                      >
                      {{SoftwareList[oneIndex].domainContent}}
                      </p>
                      <p class="two-title-t-t">
                        了解更多&nbsp;&nbsp;
                        <img
                          style="margin-bottom: 3px; color: #ce1200"
                          src="../assets/images/xl1.png"
                        />
                      </p>
                    </div>-->
	                  <!-- <p
											v-if="SoftwareList[oneIndex].domainParent == 1"
											class="two-title-t-1"
										>
											关于内容创作
										</p>
										<p
											v-if="SoftwareList[oneIndex].domainParent == 1"
											class="two-title-t-2"
										>
											稳定均衡的性能表现以及良好的兼容性，高负载下长期安静和稳定运行，强大的处理器、显卡性能和解码能力，
											超大内存满足多轨需求，良好的拓展性，适合办公需求
										</p>
										<p
											v-if="SoftwareList[oneIndex].domainParent == 2"
											class="two-title-t-1"
										>
											关于工程制造
										</p>
										<p
											v-if="SoftwareList[oneIndex].domainParent == 2"
											class="two-title-t-2"
										>
											强大的多线程处理器保障多任务的执行，超大的内存确保系统快速响应、极速运行，全面的兼容性，素材导出速度快速且稳定
										</p>
										<p
											v-if="SoftwareList[oneIndex].domainParent == 3"
											class="two-title-t-1"
										>
											关于科学计算
										</p>
										<p
											v-if="SoftwareList[oneIndex].domainParent == 3"
											class="two-title-t-2"
										>
											我们推荐高性能GPU驱动机器学习和推动人工智能研究快速发展，对于深度学习训练，最佳选择将取决于应用程序的并行可伸缩性、
											内存访问模式以及GPU加速是否可用。数据科学与科学计算类似，数据分析、提取、转换和加载（ETL）
											以及探索性数据分析（EDA）是机器学习项目的关键组成部分，也是业务流程和预测不可或缺的部分。
										</p>
										<p
											v-if="SoftwareList[oneIndex].domainParent == 4"
											class="two-title-t-1"
										>
											关于新媒体&其他
										</p>
										<p
											v-if="SoftwareList[oneIndex].domainParent == 4"
											class="two-title-t-2"
										>
											经过实验室频繁测试和验证，拥有稳定均衡的性能表现并确保兼容性,安静运行，相对紧凑的体积，适合办公环境的体
											积尺寸，众多规格的接口和更好的板卡拓展性，更强的性能满足未来
											4K 甚至 8K 的体验
										</p>
										<p
											v-if="SoftwareList[oneIndex].domainParent == 5"
											class="two-title-t-1"
										>
											关于商务办公
										</p>
										<p
											v-if="SoftwareList[oneIndex].domainParent == 5"
											class="two-title-t-2"
										>
											高效节能、性能主流，外观小巧且兼具性价比的产品。精致小巧，接口丰富，安静运行，质量可靠，为您提供优秀的办
											公娱乐体验
										</p> -->
                    <!-- <div v-else></div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="item-menu"
            v-for="(item, index) in textITList6"
            :key="item.id + index"
            @mouseenter="menuContent6(item)"
            :class="{ active: item.num === itemId }"
            @click="$router.push('/Customize')"
          >
            <span class="span-1">{{ item.text }}</span>
          </div>
          <div
              class="item-menu"
              v-for="(item, index) in textITList7"
              :key="item.id + index"
              @mouseenter="menuContent7(item)"
              :class="{ active: item.num === itemId }"
                @click="$router.push('/Scheme')"
          >
            <span class="span-1">{{ item.text }}</span>
          </div>
          <!-- 合作案例 -->
           <div
            class="item-menu"
            v-for="(item, index) in textITList2"
            :key="item.id + index"
            @mouseenter="menuContent2(item)"
            :class="{ active: item.num === itemId }"
            @click="$router.push('/solutions')"
          >
            <span class="span-1">{{ item.text }}</span>
          </div>
          <div
            class="item-menu"
            v-for="(item, index) in textITList3"
            :key="item.id + index"
            @mouseenter="menuContent3(item)"
            :class="{ active: item.num === itemId }"
            @click="$router.push('/support')"
          >
            <span class="span-1">{{ item.text }}</span>
          </div>
          <div
            class="item-menu"
            v-for="(item, index) in textITList4"
            :key="item.id + index"
            @mouseenter="menuContent4(item)"
            :class="{ active: item.num === itemId }"
            @click="$router.push('/aboutUs')"
          >
            <span class="span-1">{{ item.text }}</span>
          </div>
          <!-- <div
            class="item-menu"
            v-for="(item, index) in textITList5"
            :key="item.id + index"
            @mouseenter="menuContent5(item)"
            :class="{ active: item.num === itemId }"
            @click="$router.push('/hard')"
          >
            <span class="span-1">{{ item.text }}</span>
          </div> -->
        </div>
      </div>
    </div>
    <div class="header-right">
      <ui>
        <li>
          <div class="login-nav">
            <div style="position: relative; top: -8px">
              <div class="searchborder" @click="handleSearch">
                <el-image
                      class="searchborder-1"
                      :src="require('../assets/softchoose/searchwhite.png')"
                    ></el-image>
                <el-image
                    class="searchborder-1"
                    style="position:absolute;opacity: 0"
                    :src="require('../assets/softchoose/search2.png')"
                ></el-image>
                  <div class="searchborder-2">搜索</div>
              </div>
            </div>
            <div class="cart-navCopy">
              <img
                src="../assets/banner/message.png"
                style="margin-bottom: 2px"
              />
              <el-badge is-dot v-if="redTop === true && useInfo">
                <div
                  @click="$router.push('/messageCenter')"
                  style="margin-bottom: 3px"
                >
                  消息
                </div>
              </el-badge>
              <el-badge v-else>
                <span class="dotRed" v-if="!useInfo && redTop === true"></span>
                <div
                  @click="$router.push('/messageCenter')"
                  style="margin-bottom: 3px"
                >
                  消息
                </div>
              </el-badge>
            </div>
            <div v-if="useInfo">
              <el-dropdown class="loginIndexImg">
                <div
                  style="
                    width: 120px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    position: relative;
                    top: -2px;
                    outline: none;
                  "
                >
                  <span>
                    <img
                      v-if="useInfo.headImgurl"
                      :src="useInfo.headImgurl"
                      class="headerImg"
                      alt=""
                    />
                    <img
                      v-else
                      src="../assets/userImg.png"
                      class="headerImg"
                      alt=""
                    />
                  </span>
                  <span style="position: relative; top: 1px;outline: none;">
                    {{ useInfo.nickName }}
                  </span>
                </div>
                <template #dropdown>
                  <el-dropdown-menu>
	                  <el-dropdown-item @click="toDiscountInformation"
	                    >优惠信息</el-dropdown-item
	                  >
                    <el-dropdown-item @click="handleClick"
                      >退出登录</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>
            <div class="nav-nav-left" v-else>
              <span class="nav-left"
                ><img
                  style="margin-bottom: 2px"
                  src="../assets/banner/my.png" /></span
              ><span @click="isLogin" v-track="{event:'登录',page:'登录页面'}">登录</span>
            </div>
            <div class="cart-nav">
              <img
                src="./img/order.png"
                style="margin-bottom: 2px"
              />
              <span @click="isLogin">我的订单</span>
            </div>
            <div class="cart-nav1" style="margin-left: 20px;">
              <img
                src="../assets/banner/shopCar.png"
                style="margin-bottom: 2px"
              />
              <span @click="shopping">购物车</span>
              <div class="cart-sign" v-if="shopcount > 0">{{shopcount}}</div>
            </div>
          </div>
        </li>
      </ui>
    </div>
    <!--<el-dialog
      style="
        border-radius: 20px;
        width: 23%;
        height: 49%;
        position: relative;
      "
      @close="closeTime"
      :show-close="false"
      destroy-on-close="true"
      v-model="isVisible"
    >
      <IsLogin style="position: absolute; left: -63px; top: 30px" />
    </el-dialog>-->
    <el-dialog
      style="
        width: 520px;
        height: 420px;
        background: #ffffff;
        border-radius: 20px;
      "
      :show-close="false"
      :close-on-click-modal="false"
      destroy-on-close="true"
      v-model="isFirstLogin"
    >
      <FirstLogin @phoneData="phoneData"></FirstLogin>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
// import { getList, commercial } from "@/api/nmgdhome";
import { EnterpriseIT, Software, listSoftware,shopCount } from "@/api/Home/index.js";
import { MessageRed } from "../api/messageDetail/index.js";

import IsLogin from "./isLogin";
import FirstLogin from "./firstLogin";
import { UserLogin, getUserInfo } from "@/api/login/index";
import { ElMessage } from "element-plus";
import emitter from "@/utils/eventBus";
const titleName = ref([
  {
    id: 0,
    title1: "关于内容创作",
    name1:
      "稳定均衡的性能表现以及良好的兼容性，高负载下长期安静和稳定运行，强大的处理器、显卡性能和解码能力， 超大内存满足多轨需求，良好的拓展性，适合办公需求",
  },
  {
    id: 1,
    title2: "关于工程制造",
    name2:
      "强大的多线程处理器保障多任务的执行，超大的内存确保系统快速响应、极速运行，全面的兼容性，素材导出速度快速且稳定",
  },
  {
    id: 2,
    title3: "关于科学计算",
    name3:
      "我们推荐高性能GPU驱动机器学习和推动人工智能研究快速发展，对于深度学习训练，最佳选择将取决于应用程序的并行可伸缩性、 内存访问模式以及GPU加速是否可用。数据科学与科学计算类似，数据分析、提取、转换和加载（ETL） 以及探索性数据分析（EDA）是机器学习项目的关键组成部分，也是业务流程和预测不可或缺的部分。",
  },
  {
    id: 3,
    title4: "关于新媒体&其他",
    name4:
      "经过实验室频繁测试和验证，拥有稳定均衡的性能表现并确保兼容性,安静运行，相对紧凑的体积，适合办公环境的体 积尺寸，众多规格的接口和更好的板卡拓展性，更强的性能满足未来 4K 甚至 8K 的体验",
  },
  {
    id: 4,
    title5: "关于商务办公",
    name5:
      "高效节能、性能主流，外观小巧且兼具性价比的产品。精致小巧，接口丰富，安静运行，质量可靠，为您提供优秀的办公娱乐体验",
  },
]);
const textITList = ref([{ num: 0, id: 6, text: "商用产品" }]);
const textITList1 = ref([{ num: 1, id: 1, text: "应用软件" }]);
//const textITList2 = ref([{ num: 2, id: 2, text: "合作案例" }]);
const textITList3 = ref([{ num: 3, id: 3, text: "服务支持" }]);
const textITList4 = ref([{ num: 4, id: 4, text: "了解我们" }]);
const textITList5 = ref([{ num: 5, id: 5, text: "咨询专家" }]);
//const textITList6 = ref([{ num: 6, id: 6, text: "硬件定制" }]);
const textITList6 = ref([{ num: 6, id: 6, text: "定制" }]);
//const textITList7 = ref([{ num: 7, id: 7, text: "其他定制" }]);
const textITList8 = ref([{ num: 8, id: 8, text: "首页" }]);
const isFirstLogin = ref(false);
//const isVisible = ref(false);
const openidKey = ref();
const useInfo = ref(null);

// 鼠标移出导航栏，当前页面菜单高亮
const mouseleave = () => {
	const hash = window.location.hash
	if(hash == '#/'){
		itemId.value = 8
	}else if(hash == '#/commercialProduct'){
		itemId.value = 0
	}else if(hash == '#/Customize'){
		itemId.value = 6
	}else if(hash == '#/support'){
		itemId.value = 3
	}else if(hash == '#/aboutUs'){
		itemId.value = 4
	}else if(hash.indexOf('#/solve') != -1 || hash.indexOf('#/profession') != -1){
		itemId.value = 1
	}
}

const isCode = () => {
  if (window.location.href.split("?")[1] != undefined) {
    const currentPageUrl = window.location.href
      .split("?")[1]
      .split("=")[1]
      .split("&")[0];
    let data = {
      credential: currentPageUrl,
    };
    UserLogin(data).then((res) => {
      if (res.code == 200) {
        let token = res.data.token;
        localStorage.setItem("token", token);
        if (token) {
          getInfo(token);
        }
      } else if (res.code == 403) {
        isFirstLogin.value = true;
        openidKey.value = res.message;
      }
    });
  }
};
const getInfo = () => {
  getUserInfo().then((res) => {
    if (res.code == 200) {
      useInfo.value = res.data.user;
      localStorage.setItem("user", JSON.stringify(res.data.user));
      ElMessage({
        message: "登录成功",
        type: "success",
      });
      isFirstLogin.value = false;
    } else {
      ElMessage({
        message: res.message,
        type: "error",
      });
    }
  });
};
const handleSearch = () => {
  router.push("/searchgoodinfo");
}
const phoneData = (val1, val2, val3) => {
  let data = {
    username: val1,
    credential: val2,
    openidKey: openidKey.value,
    uuid: val3,
  };
  let option = {
    message: "message",
  };
  UserLogin(data, option).then((res) => {
    if (res.code == 200) {
      let token = res.data.token;
      localStorage.setItem("token", token);
      if (token) {
        getInfo(token);
      }
    } else {
      ElMessage({
        message: res.message,
        type: "error",
      });
    }
  });
};

// 点击跳转页面
const routerPush = () => {
  document.body.style='overflow:none;padding-right:0'
  router.push({
    path: `/desktop/${moreData.value}`,
  });
};
const isLogin = () => {
  if (useInfo.value == null) {
    //isVisible.value = true;
    emitter.emit("openLogin",{openLogin:true})
  } else {
    router.push("/orderCenter");
  }
  let currentPath = location.href;
  // if (currentPath === "http://172.16.5.34/#/orderCenter") {
  //   location.reload();
  //   router.push("/orderCenter");
  // }
  // https://www.iningmei.com/#/ 上线之后
  // https://testshop.iningmei.com/#/ 测试
  if (currentPath === "https://www.iningmei.com/#/orderCenter") {
    location.reload();
    router.push("/orderCenter");
  }
};
const router = useRouter();
const toDiscountInformation = () => {
	router.push("/couponList");
}
const handleClick = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  router.go(0);
  ElMessage({
    message: "退出成功",
    type: "success",
  });
};
const toUpPush = () => {
  router.push("/commercialProduct");
};
const detailsGoods = (k,id) => {
  event.stopPropagation();
  if(k.machineType == 3 || k.machineType == 5 || k.machineType == 6){
	  //router.push("/detailsGoodsNew/"+ k.id + "/1");
    router.push({
	    name: "detailsGoodsNew",
	    query: {
				id: k.id,
		    houseNo: 1
	    }
		});
  }else{
	  //router.push("/detailsGoods/"+ k.id + "/1");
    router.push({
	    name: "detailsGoods",
	    query: {
		    id: k.id,
		    houseNo: 1
	    }
    });
  }
};
const itemId = ref();
const menuContent = (item) => {
  getEnterpriseIT();
  itemId.value = item.num;
};
const menuContent1 = (item) => {
  getSoftware();
  itemId.value = item.num;
	document.getElementById('utilitySoftwareId').click();
};
const menuContent2 = (item) => {
  itemId.value = item.num;
};
const menuContent3 = (item) => {
  itemId.value = item.num;
};
const menuContent4 = (item) => {
  itemId.value = item.num;
};
const menuContent5 = (item) => {
  itemId.value = item.num;
};
const menuContent6 = (item) => {
  itemId.value = item.num;
};
const menuContent7 = (item) => {
  itemId.value = item.num;
};
const menuContent8 = (item) => {
  itemId.value = item.num;
};
const SoftwareData = ref([]);
const getTopSoftware = async () => {
  try {
    let data = await listSoftware();
    SoftwareData.value = data.data;
  } catch (error) {
    console.log(error);
  }
};
// 商用商品
const menuId = ref(0);
const ITList = ref([]);
const moreData = ref("4");
// 点击切换
const menuLeft = (i, item) => {
  console.log(i, item);
  moreData.value = item.machineType;
  menuId.value = i;
};
// 请求数据
const getEnterpriseIT = async () => {
  try {
    let data = await EnterpriseIT();
    // console.log(ITList, "商用");
    if (data.code === 200) {
      ITList.value = data.data;
    }
  } catch (error) {
    console.log(error);
  }
};
emitter.on("addshop", (val) => {
  if(val.addshop){
    getShopCount()
  }
});
// 行业软件方案
const oneIndex = ref(0);
const shopcount = ref(0);
const SoftwareList = ref([]);
const handleOne = (i) => {
  oneIndex.value = i;
};
const shopping = () => {
  const token = localStorage.getItem("token");
  if (token) {
    router.push("/goShopping");
  }else{
    emitter.emit("openLogin", { openLogin: true });
  }
}
// 请求数据
const getSoftware = async () => {
  try {
    let data = await Software();
    if (data.code === 200) {
      SoftwareList.value = data.data;
      SoftwareList.value.forEach((item) => {
        item.childTable = titleName.value;
      });
    }
  } catch (error) {
    console.log(error);
  }
};
const value = ref("");
// const jumpSoftware = (value)=>{
//   router.push("/profession/" + value);
// }
const handelChange = (value) => {
  router.push("/profession/" + value);
};
// 红点
const redTop = ref(null);
const titleList = ref([]);
const getShopCount = () => {
  const token = localStorage.getItem("token");
  if (token) {
    shopCount().then((res) => {
      shopcount.value = res.data
    })
  }
}
const getMessageRed = async () => {
  try {
    let data = await MessageRed();
    if (data.code === 200) {
      titleList.value = data.data;
      // console.log(titleList.value, "消息中心");
      const res = titleList.value.some((item) => item.readState === 0);
      // console.log(res, "最后数据");
      redTop.value = res;
    } else {
      return "暂无数据";
    }
  } catch (error) {
    console.log(error);
  }
};
onMounted(() => {
  getMessageRed();
  getTopSoftware();
  getShopCount();
  const data = JSON.parse(localStorage.getItem("user"));
  if (data != null) {
    useInfo.value = data;
  } else {
    isCode();
  }
});
</script>

<style scoped lang="less">
::v-deep .el-input--small {
  /* --el-input-height: var(--el-component-size-small); */
  height: 36px;
  font-size: 12px;
}
::v-deep .el-badge__content.is-fixed {
  position: absolute;
  left: 20px;
  border: none;
  right: calc(1px + var(--el-badge-size) / 2);
  transform: translateY(-50%) translateX(100%);
  z-index: var(--el-index-normal);
}
::v-deep .el-badge__content--danger {
  background-color: #f8392d;
}
.header-container {
  display: flex;
  .nav-header {
    .container {
      position: relative;
      // height: 70px;
      // line-height: 70px;
      display: flex;
      .header-logo {
        width: 117px;
        margin-left: 180px;
        a {
          display: block;
          height: 66px;
          //width: 100%;
          ////background: url(./img/k-1.png) no-repeat center 12px / contain;
          margin-bottom: 5px;
	        img{
		        position: relative;
		        top:50%;
		        transform: translateY(-50%);
		        margin-top: 5px;
		        width: 117px;
		        height: 30px;
	        }
        }
      }
      .header-menu {
        display: flex;
        padding-left: 72px;
        // margin-top: 26px;
        // padding-top: 26px;
        .item-menu {
          // display: inline-block;
	        display: flex;
	        justify-content: center;
	        min-width: 47px;
          padding-top: 26px;
          font-size: 14px;
          font-weight: 800;
	        //text-align: center;
	        color: #ffffff;
          margin-right: 32px;
          cursor: pointer;
          &.active {
            // border-color: #ce1200;
            // background: lighten(#ce1200, 50%);
	          color: #CE1220;
            border-bottom: 2px solid #CE1220;
            // padding-bottom: 20px;
          }
          .span-1 {
            display: inline-block;
            cursor: pointer;
            // margin-top: 30px;
            // padding-top: 30px;
            // &:hover {
            //   color: @xtxColor;
            // }
            &.active {
              // border-color: #ce1200;
              // background: lighten(#ce1200, 50%);
	            color: #CE1220;
              border-bottom: 2px solid #CE1220;
              // padding-bottom: 20px;
            }
          }
          a {
            cursor: pointer;
            color: #ffffff;
            &:hover {
              color: @xtxColor;
            }
          }
          &:hover {
            .children {
              position: absolute;
              height: 650px;
              width: 100vw;
              opacity: 1;
              z-index: 9999;
              overscroll-behavior: contain;
              // overflow: auto;
              overflow: scroll;
            }
          }
          &:hover {
            .children-1 {
              position: absolute;
              height: 450px;
              width: 100vw;
              opacity: 1;
              z-index: 9999;
              overscroll-behavior: contain;
              // overflow: auto;
              overflow: scroll;
            }
          }
          .children {
            position: absolute;
            top: 72px;
            left: 0;
            right: 0;
            width: 1920px;
            height: 0px;
            opacity: 0;
            overflow: hidden;
            box-shadow: 0px 7px 6px 0px rgba(0, 0, 0, 0.11);
            z-index: 10;
            transition: all 0.5s;
            background-color: #ffffff;
            display: block;
            // 第一个
            .children-one {
              display: flex;
              .children-one-1 {
                width: 360px;
                height: 700px;
                color: #666666;
                padding-left: 256px;
                padding-top: 70px;
                overflow-y: scroll;
                .one-1-1 {
                  font-size: 14px;
                  font-weight: 400;
                  color: #333333;
                  height: 45px;
                  &:hover {
                    color: @xtxColor;
                  }
                }
                .one-1-2 {
                  display: inline-block;
                  margin-right: 12px;
                  width: 90px;
                  height: 31px;
                  line-height: 31px;
                  padding-left: 10px;
                  &.active {
                    border-color: #ce1200;
                    background: lighten(#ce1200, 50%);
                    color: #ce1200;
                    background: #f2f2f2;
                  }
                }
              }
              .children1-one-two {
                width: 1560px;
                display: flex;
                .children-one-2 {
                  width: 910px;
                  display: flex;
                  margin-top: 60px;
                  margin-left: 40px;
                  .two-1 {
                    width: 200px;
                    height: 230px;
                    background: #f2f2f2;
                    margin-left: 30px;
                    .two-1-2 {
                      width: 110px;
                      height: 133px;
                      img {
                        display: inline-block;
                        width: 110px;
                        height: 133px;
                        margin: 32px 45px 16px 45px;
                      }
                    }
                    .two-1-3 {
                      font-size: 13px;
                      font-weight: 400;
                      width: 168px;
                      height: 38px;
                      text-align: center;
                      color: #333333;
                      margin-top: 44px;
                      margin-left: 15px;
                      display: -webkit-box;
                      -webkit-box-orient: vertical;
                      -webkit-line-clamp: 2;
                      overflow: hidden;
                      /* 设置文本超出部分省略 */
                      text-overflow: ellipsis;
                    }
                  }
                }
                .old {
                  width: 160px;
                  height: 40px;
                  line-height: 40px;
                  border: 1px solid #aaaaaa;
                  font-size: 12px;
                  font-weight: 400;
                  color: #333333;
                  margin-left: 435px;
                  margin-top: 40px;
                  text-align: center;
                }
                .children-one-3 {
                  width: 270px;
                  height: 147px;
                  border: 1px solid #d9d9d9;
                  font-size: 11px;
                  font-weight: 400;
                  color: #666666;
                  margin-left: 100px;
                  margin-top: 60px;
                  cursor: pointer;
                  p:nth-child(1) {
                    font-size: 13px;
                    font-weight: 400;
                    color: #333333;
                    margin: 18px 0 8px 25px;
                  }
                  p:nth-child(2) {
                    font-size: 11px;
                    font-weight: 400;
                    line-height: 1.6;
                    color: #666666;
                    margin: 0 24px;
                  }
                  P:nth-child(3) {
                    font-size: 13px;
                    font-weight: 400;
                    color: #ce1200;
                    margin-left: 25px;
                    margin-top: 4px;
                    cursor: pointer;
                  }
                }
              }
            }
            // 第二个
            .children-one-w {
              display: flex;
              .children-one-1-w {
                width: 360px;
                height: 630px;
                color: #666666;
                padding-left: 185px;
                overflow-y: scroll;
                padding-top: 61px;
                .one-1-1-w {
                  width: 140px;
                  height: 15px;
                  font-size: 14px;
                  font-weight: 400;
                  color: #333333;
                  margin-bottom: 31px;
                  &:hover {
                    color: @xtxColor;
                    // border-left: 5px solid #ce1200;
                  }
                }
                .one-1-2-w {
                  display: inline-block;
                  margin-right: 12px;
                  width: 145px;
                  height: 31px;
                  line-height: 31px;
                  padding-left: 10px;
                  &.active {
                    background: lighten(#ce1200, 50%);
                    color: #ce1200;
                    background: #f2f2f2;
                  }
                }
              }
              .children1-one-two-w {
                width: 1560px;
                height: 350px;
                display: flex;
                margin-left: 70px;
                .children-one-2-w {
                  width: 910px;
                  height: 350px;
                  display: flex;
                  flex-wrap: wrap;
                  margin-top: 60px;
                  overflow-y: scroll;
                  ui {
                    width: 100px;
                    height: 140px;
                    margin-right: 30px;
                    .two-1-w {
                      width: 100px;
                      height: 140px;
                      .two-1-2-w {
                        width: 100px;
                        height: 100px;
                        img {
                          display: inline-block;
                          width: 100px;
                          height: 100px;
                          border-radius: 15px;
                        }
                      }
                      .two-1-3-w {
                        font-size: 13px;
                        font-weight: 400;
                        color: #333333;
                        text-align: center;
                        width: 100px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        margin-top: 8px;
                        margin-bottom: 24px;
                      }
                    }
                  }
                }
                .children-one-3-w {
                  width: 270px;
                  height: 147px;
                  border: 1px solid #d9d9d9;
                  font-size: 11px;
                  font-weight: 400;
                  color: #666666;
                  margin-left: 60px;
                  margin-top: 60px;
                  cursor: pointer;
                  p:nth-child(1) {
                    font-size: 13px;
                    font-weight: 400;
                    color: #333333;
                    margin: 18px 0 8px 25px;
                  }
                  p:nth-child(2) {
                    font-size: 11px;
                    font-weight: 400;
                    line-height: 1.6;
                    color: #666666;
                    margin: 0 24px;
                    font-family: Microsoft YaHei;
                  }
                  P:nth-child(3) {
                    font-size: 13px;
                    font-weight: 400;
                    color: #ce1200;
                    font-weight: 400;
                    margin-left: 25px;
                    margin-top: 4px;
                  }
                }
                .children-one-4-w-copy {
                  width: 270px;
                  height: 147px;
                  border: 1px solid #ffba8a;
                  margin-left: -270px;
                  margin-top: 226px;
                  background: linear-gradient(
                    180deg,
                    #ffefe3 0%,
                    rgba(255, 244, 237, 0) 100%
                  );
                  cursor: pointer;
                  .two-title-t-1 {
                    font-size: 14px;
                    font-weight: 600;
                    color: #ce1200;
                    margin: 17px 0 9px 24px;
                  }
                  .two-title-t-2 {
                    line-height: 1.6;
                    width: 221px;
                    height: 50px;
                    font-size: 11px;
                    font-weight: 100;
                    color: #666666;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    word-break: break-all;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 3;
                    margin: 0 0 10px 24px;
                  }
                  .two-title-t-t {
                    font-size: 12px;
                    font-weight: 10;
                    color: #ce1200;
                    margin-left: 25px;
                  }
                }
              }
            }
          }
          .children-1 {
            position: absolute;
            top: 72px;
            left: 0;
            right: 0;
            width: 1920px;
            height: 0px;
            opacity: 0;
            overflow: hidden;
            box-shadow: 0px 7px 6px 0px rgba(0, 0, 0, 0.11);
            z-index: 10;
            transition: all 0.5s;
            background-color: #ffffff;
            display: block;
            // 第一个
            .children-one {
              display: flex;
              .children-one-1 {
                width: 360px;
                height: 420px;
                color: #666666;
                padding-left: 256px;
                padding-top: 70px;
                overflow-y: scroll;
                .one-1-1 {
                  font-size: 14px;
                  font-weight: 400;
                  color: #333333;
                  height: 45px;
                  &:hover {
                    color: @xtxColor;
                  }
                }
                .one-1-2 {
                  display: inline-block;
                  margin-right: 12px;
                  width: 90px;
                  height: 31px;
                  line-height: 31px;
                  padding-left: 10px;
                  &.active {
                    border-color: #ce1200;
                    background: lighten(#ce1200, 50%);
                    color: #ce1200;
                    background: #f2f2f2;
                  }
                }
              }
              .children1-one-two {
                width: 1560px;
                display: flex;
                .children-one-2 {
                  width: 910px;
                  display: flex;
                  margin-top: 60px;
                  margin-left: 40px;
                  .two-1 {
                    width: 200px;
                    height: 230px;
                    background: #f2f2f2;
                    margin-left: 30px;
                    .two-1-2 {
                      width: 140px;
                      height: 143px;
                      img {
                        display: inline-block;
                        width: 140px;
                        height: 140px;
                        margin: 32px 45px 16px 30px;
                      }
                    }
                    .two-1-3 {
                      font-size: 13px;
                      font-weight: 400;
                      width: 168px;
                      height: 38px;
                      text-align: center;
                      color: #333333;
                      margin-top: 44px;
                      margin-left: 15px;
                      display: -webkit-box;
                      -webkit-box-orient: vertical;
                      -webkit-line-clamp: 2;
                      overflow: hidden;
                      /* 设置文本超出部分省略 */
                      text-overflow: ellipsis;
                    }
                  }
                }
                .old {
                  width: 160px;
                  height: 40px;
                  line-height: 40px;
                  border: 1px solid #aaaaaa;
                  font-size: 12px;
                  font-weight: 400;
                  color: #333333;
                  margin-left: 435px;
                  margin-top: 40px;
                  text-align: center;
                }
                .children-one-3 {
                  width: 270px;
                  height: 147px;
                  border: 1px solid #d9d9d9;
                  font-size: 11px;
                  font-weight: 400;
                  color: #666666;
                  margin-left: 100px;
                  margin-top: 60px;
                  cursor: pointer;
                  p:nth-child(1) {
                    font-size: 13px;
                    font-weight: 400;
                    color: #333333;
                    margin: 18px 0 8px 25px;
                  }
                  p:nth-child(2) {
                    font-size: 11px;
                    font-weight: 400;
                    line-height: 1.6;
                    color: #666666;
                    margin: 0 24px;
                  }
                  P:nth-child(3) {
                    font-size: 13px;
                    font-weight: 400;
                    color: #ce1200;
                    margin-left: 25px;
                    margin-top: 4px;
                    cursor: pointer;
                  }
                }
              }
            }
            // 第二个
            .children-one-w {
              display: flex;
              .children-one-1-w {
                width: 360px;
                height: 700px;
                color: #666666;
                padding-left: 185px;
                overflow-y: scroll;
                padding-top: 61px;
                .one-1-1-w {
                  width: 140px;
                  height: 15px;
                  font-size: 14px;
                  font-weight: 400;
                  color: #333333;
                  margin-bottom: 31px;
                  &:hover {
                    color: @xtxColor;
                    // border-left: 5px solid #ce1200;
                  }
                }
                .one-1-2-w {
                  display: inline-block;
                  margin-right: 12px;
                  width: 145px;
                  height: 31px;
                  line-height: 31px;
                  padding-left: 10px;
                  &.active {
                    background: lighten(#ce1200, 50%);
                    color: #ce1200;
                    background: #f2f2f2;
                  }
                }
              }
              .children1-one-two-w {
                width: 1560px;
                height: 350px;
                display: flex;
                margin-left: 70px;
                .children-one-2-w {
                  width: 910px;
                  height: 350px;
                  display: flex;
                  flex-wrap: wrap;
                  margin-top: 60px;
                  overflow-y: scroll;
                  ui {
                    width: 100px;
                    height: 140px;
                    margin-right: 30px;
                    .two-1-w {
                      width: 100px;
                      height: 140px;
                      .two-1-2-w {
                        width: 100px;
                        height: 100px;
                        img {
                          display: inline-block;
                          width: 100px;
                          height: 100px;
                          border-radius: 15px;
                        }
                      }
                      .two-1-3-w {
                        font-size: 13px;
                        font-weight: 400;
                        color: #333333;
                        text-align: center;
                        width: 100px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        margin-top: 8px;
                        margin-bottom: 24px;
                      }
                    }
                  }
                }
                .children-one-3-w {
                  width: 270px;
                  height: 147px;
                  border: 1px solid #d9d9d9;
                  font-size: 11px;
                  font-weight: 400;
                  color: #666666;
                  margin-left: 60px;
                  margin-top: 60px;
                  cursor: pointer;
                  p:nth-child(1) {
                    font-size: 13px;
                    font-weight: 400;
                    color: #333333;
                    margin: 18px 0 8px 25px;
                  }
                  p:nth-child(2) {
                    font-size: 11px;
                    font-weight: 400;
                    line-height: 1.6;
                    color: #666666;
                    margin: 0 24px;
                    font-family: Microsoft YaHei;
                  }
                  P:nth-child(3) {
                    font-size: 13px;
                    font-weight: 400;
                    color: #ce1200;
                    font-weight: 400;
                    margin-left: 25px;
                    margin-top: 4px;
                  }
                }
                .children-one-4-w-copy {
                  width: 270px;
                  height: 147px;
                  border: 1px solid #ffba8a;
                  margin-left: -270px;
                  margin-top: 226px;
                  background: linear-gradient(
                    180deg,
                    #ffefe3 0%,
                    rgba(255, 244, 237, 0) 100%
                  );
                  cursor: pointer;
                  .two-title-t-1 {
                    font-size: 14px;
                    font-weight: 600;
                    color: #ce1200;
                    margin: 17px 0 9px 24px;
                  }
                  .two-title-t-2 {
                    line-height: 1.6;
                    width: 221px;
                    font-size: 11px;
                    font-weight: 100;
                    color: #666666;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    word-break: break-all;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 3;
                    margin: 0 0 10px 24px;
                  }
                  .two-title-t-t {
                    font-size: 12px;
                    font-weight: 10;
                    color: #ce1200;
                    margin-left: 25px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .header-right {
    //margin-left: 269px; // 导航栏多一个菜单
	  margin-left: 369px;
    margin-top: 26px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    .loginIndexImg {
      text-align: center;
      .headerImg {
        display: inline-block;
        width: 24px;
        height: 24px;
        border-radius: 12px;
        margin-right: 7px;
      }
    }
    .login-nav {
      display: flex;
      .nav-nav-left {
        padding-left: 23px;
        padding-right: 20px;
        .nav-left {
          margin-right: 6px;
	        img{
		        width: 12px;
		        height: 14px;
	        }
        }
      }
      /deep/ .el-select .el-input__wrapper {
        cursor: pointer;
        background-color: transparent;
      }
      .searchborder{
        width: 92px;
        height: 36px;
        border-radius: 18px;
        color: #FFFFFF;
        border: 1px solid #FFFFFF;
        display: flex;
        transition: width 0.4s,margin-left 0.4s;
        &:hover{
          width: 257px;
          //border: 1px solid #ce1200;
          color: #ce1200;
          margin-left: -165px;
        }
        &:hover .searchborder-1:nth-child(1){
          opacity: 0;
          position: absolute !important;
        }
        &:hover .searchborder-1:nth-child(2){
          opacity: 1 !important;
          position: relative !important;
        }
        .searchborder-1{
          width: 16px;
          height: 16px;
          position: relative;
          top: 10px;
          left: 23px;
        }
        .searchborder-2{
          width: 25px;
          height: 13px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 12px;
          //color: #FFFFFF;
          position: relative;
          top: 9px;
          left: 29px;
        }
      }
      .opcitySearch {
        width: 130px;
        /deep/ .el-icon svg {
          height: 1em;
          width: 1em;
          color: white;
        }
        /deep/ input[type="text"],
        input[type="search"],
        input[type="password"],
        input[type="checkbox"] {
          padding: 0;
          outline: none;
          border: none;
          -webkit-appearance: none;
          color: white;
        }
      }
      .opcitySearch ::placeholder {
        color: #ffffff;
      }
      .cart-nav {
        img {
	        width: 12px;
	        height: 14px;
          margin-right: 6px;
        }
      }
      .cart-nav1 {
        img {
	        width: 15px;
	        height: 14px;
          margin-right: 6px;
        }
        .cart-sign{
          width: 12px;
          height: 12px;
          background: #F8392D;
          line-height: 12px;
          border-radius: 50%;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 6px;
          color: #FFFFFF;
          text-align: center;
          position: relative;
          left: 58px;
          top: -24px;
        }
      }
      .cart-navCopy {
        margin-left: 20px;
        // margin-right: 21px;
        img {
	        width: 16px;
	        height: 16px;
          margin-right: 6px;
        }
        .dotRed {
          display: inline-block;
          width: 8px;
          height: 8px;
          background-color: #f56c6c;
          border-radius: 50%;
          position: absolute;
          top: -5px;
          left: 29px;
        }
      }
    }
    ::v-deep .el-select .el-input__wrapper {
      border-radius: 30px;
    }
  }
}

::v-deep .el-dropdown {
  color: #fff;
}
.children-one-2-w::-webkit-scrollbar {
  display: none;
}

.children-one-1-w::-webkit-scrollbar {
  width: 2px; //滚动条宽度
}
.children-one-1-w::-webkit-scrollbar-thumb {
  //上层
  border-radius: 10px; //滚动条圆弧半径
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2); //滚动条阴影
  background: rgba(0, 0, 0, 0.2); //背景颜色
}
.children-one-1-w::-webkit-scrollbar-track {
  //底层
  -webkit-box-shadow: inset 0 0 5px #e1e1e1;
  border-radius: 0;
  background: #e1e1e1;
}
.children-one-1::-webkit-scrollbar {
  width: 2px; //滚动条宽度
}
.children-one-1::-webkit-scrollbar-thumb {
  //上层
  border-radius: 10px; //滚动条圆弧半径
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2); //滚动条阴影
  background: rgba(0, 0, 0, 0.2); //背景颜色
}
.children-one-1::-webkit-scrollbar-track {
  //底层
  -webkit-box-shadow: inset 0 0 5px #e1e1e1;
  border-radius: 0;
  background: #e1e1e1;
}

.children-1::-webkit-scrollbar {
  width: 0px; //滚动条宽度
}
.children-1::-webkit-scrollbar-thumb {
  //上层
  border-radius: 0px; //滚动条圆弧半径
  -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.2); //滚动条阴影
  background: rgba(0, 0, 0, 0.2); //背景颜色
}
.children-1::-webkit-scrollbar-track {
  //底层
  -webkit-box-shadow: inset 0 0 0px #fff;
  border-radius: 0;
  background: #fff;
}
.children::-webkit-scrollbar {
  width: 0px; //滚动条宽度
}
.children::-webkit-scrollbar-thumb {
  //上层
  border-radius: 0px; //滚动条圆弧半径
  -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.2); //滚动条阴影
  background: rgba(0, 0, 0, 0.2); //背景颜色
}
.children::-webkit-scrollbar-track {
  //底层
  -webkit-box-shadow: inset 0 0 0px #fff;
  border-radius: 0;
  background: #fff;
}
</style>
